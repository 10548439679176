import React, { useState, useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import ImageViewer from 'react-simple-image-viewer';

//const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const items = [
  require('../images/product_1.PNG').default,
  require('../images/product_2.PNG').default,
  require('../images/product_3.PNG').default,
  require('../images/product_4.PNG').default,
  require('../images/product_5.PNG').default,
  require('../images/product_6.PNG').default,
  require('../images/product_7.PNG').default,
  require('../images/product_8.PNG').default,
  require('../images/product_9.PNG').default,
  require('../images/product_10.PNG').default,
  require('../images/product_11.PNG').default,
  require('../images/product_12.PNG').default,
  require('../images/product_13.PNG').default,
  require('../images/product_14.PNG').default,
  require('../images/product_15.PNG').default,
  require('../images/product_16.PNG').default,
  require('../images/product_17.PNG').default,
  require('../images/product_18.PNG').default,
  require('../images/product_19.PNG').default,
  require('../images/product_20.PNG').default,
  require('../images/product_21.PNG').default,
  require('../images/product_22.PNG').default,
  require('../images/remote_1.PNG').default,
  require('../images/remote_2.PNG').default
];

const ItemsScreen = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const classes = useStyles();
  return (
    <section id="products">
      <Container>
        <Typography className={classes.title}>Our Products</Typography>
        <hr />

        <Grid container>
          {items.map((item, index) => (
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
              md={6}
              xs={12}
            >
              <img
                src={item}
                alt="product"
                className={classes.itemImage}
                onClick={() => openImageViewer(index)}
              />
            </Grid>
          ))}
          {isViewerOpen && (
            <ImageViewer
              src={items}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </Grid>
      </Container>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    marginTop: '5%',
    fontSize: '2.2em',
    fontFamily: 'kufam'
  },
  itemImage: {
    objectFit: 'cover',
    alignSelf: 'center',
    width: isMobileOnly ? windowWidth / 1.1 : windowWidth / 3,
    marginBottom: '5%',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default ItemsScreen;
