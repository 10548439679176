import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core';
import '../css/Navbar.css';
import { isMobileOnly } from 'react-device-detect';

const height = window.innerHeight;
//const width = window.innerWidth;

const HomeScreen = () => {
  const classes = useStyles();
  return (
    <Navbar
      style={{ paddingTop: 0 }}
      fixed="top"
      className={isMobileOnly ? classes.navmobile : classes.nav}
      expand="lg"
    >
      <Container className={classes.container}>
        <Navbar.Brand href="/">
          <img
            src={require('../images/logo_test.png').default}
            alt="logo"
            style={{ objectFit: 'contain', height: height / 15 }}
          />
        </Navbar.Brand>
        <Navbar.Toggle className={classes.nav} aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav navbarScroll className="navbar-nav ms-auto">
            <Nav.Link className={classes.home} href="#home">
              Home
            </Nav.Link>
            <Nav.Link className={classes.home} href="#about">
              About
            </Nav.Link>
            <Nav.Link className={classes.home} href="/#products">
              Our Products
            </Nav.Link>
            <Nav.Link className={classes.home} href="#contact">
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  home: {
    color: 'green'
    //backgroundColor: 'red'
  },
  nav: {
    backgroundColor: 'white',
    //height: height / 8,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'
  },
  navMobile: {
    backgroundColor: 'white',
    top: 0,
    height: height / 6,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'
  },
  container: {
    backgroundColor: 'white'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

export default HomeScreen;
