import React from 'react';
import { makeStyles } from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import Navbar from '../components/Navbar';
import AboutScreen from './AboutScreen';
import ItemsScreen from './ItemsScreen';
import ContactScreen from './ContactScreen';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const HomeScreen = () => {
  const classes = useStyles();
  return (
    <section id="home">
      <Navbar />
      <img
        src={require('../images/home.png').default}
        className={classes.homeImage}
        alt="home"
      />
      <AboutScreen />
      <ItemsScreen />
      <ContactScreen />
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  homeImage: {
    width: windowWidth,
    height: isMobileOnly ? windowHeight / 3 : windowHeight,
    marginTop: isMobileOnly ? '5%' : 0,
    objectFit: 'cover'
  }
}));

export default HomeScreen;
