import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import { LocationOn, Call, Email } from '@material-ui/icons';

const ContactScreen = () => {
  const classes = useStyles();
  return (
    <section id="contact">
      <Container style={{ marginBottom: '2%' }}>
        <Typography variant="h4" className={classes.title}>
          Feel Free To Contact Us
        </Typography>
        <hr />
        <div className={classes.container}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <LocationOn className={classes.icon} color="secondary" />
            <Typography variant="h6">
              Al Muwaqar Industrial City, Amman, Jordan
            </Typography>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '2%' }}
          >
            <Call className={classes.icon} color="primary" />
            <Typography variant="h6">
              Call: 00962 79 1234 333 / 00962 79 1234 888 / 00962 79 1234 666
            </Typography>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', marginTop: '2%' }}
          >
            <Email className={classes.emailIcon} />
            <Typography variant="h6">
              info@cemortv.net / S.abdo@cemortv.net
            </Typography>
          </div>
        </div>
      </Container>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    textAlign: 'center',
    fontSize: '2em',
    fontFamily: 'kufam'
  },
  icon: {
    fontSize: '2em'
  },
  emailIcon: {
    fontSize: '2em',
    color: 'orange'
  }
}));

export default ContactScreen;
