import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';

const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;

const AboutScreen = () => {
  const classes = useStyles();
  return (
    <section id="about">
      <Container>
        <Grid container>
          <Grid style={{ marginTop: '5%' }} md={6} xs={12}>
            <img
              src={require('../images/about.PNG').default}
              alt="about"
              className={classes.aboutImage}
            />
          </Grid>
          <Grid md={6} xs={12} style={{ marginTop: '5%' }}>
            <Typography className={classes.title} variant="h4">
              About US
            </Typography>
            <hr />
            <Typography>
              Sa'ed Abdo Electronic Industries Company is a Electronic and
              Electrical Appliance Industry. Our company is located in Mwaqar
              Amman Jordan. Covering more than 2,000 square meters of production
              area, owing over 50 well-trained employees. Our company has
              dedicated to further davelopment of LED TV, size from 24" to 85",
              the company of clients network has covers all over the world,
              includes Egypt, Iraq, Saudi Arabia, Tunisia, Libya + North Africa
              and so on.In Dubai, our protessional sales team always keep timely
              information communicating with customers. And share wth customers
              the most interested product information in the market. Equipped
              with dust-free workshop which has 3 module lines and 2 assembly
              lines, we have a monthly production capacity of more than 20000
              units.
            </Typography>
          </Grid>

          <Grid md={6} xs={12} style={{ marginTop: '5%' }}>
            <Typography>
              All of our products are ready with CE, ROHS, FCC, ETC, SASO, IEC,
              HDMI, DOLBY certificates, Strictly carrying out high quality
              standard and efficient production, we're always ready to provide
              qualified products at most competitive prices to our customers.
              Our company's goal is to follow the discipline and the trend, to
              make continous progress in our operation as well as our
              production. We will stick to the policy of "Quality First, Honest
              Management, Market Expansion and Steady Progress" to work together
              with our customers home and abroad and combine our effort to
              achieve a win-win situation for our common goal.
            </Typography>
          </Grid>
          <Grid md={6} xs={12} style={{ marginTop: '5%' }}>
            <img
              src={require('../images/about_2.PNG').default}
              alt="about"
              className={classes.aboutImage2}
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'kufam'
  },
  aboutImage: {
    objectFit: 'contain',
    height: isMobileOnly ? windowHeight / 5 : windowHeight / 2.7,
    width: isMobileOnly ? windowWidth / 1.1 : windowWidth / 2.5
  },
  aboutImage2: {
    width: isMobileOnly ? windowWidth / 1.1 : windowWidth / 3,
    objectFit: 'contain'
  },
  slogan: {
    //textAlign: 'center'
  }
}));

export default AboutScreen;
